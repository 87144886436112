import React from 'react';

// ------------------------------ Screen ------------------------------
// Register
import { RegisterScreen } from './features/register';
import { RegisterMemberScreen } from './features/register/member';
// Login
import { LoginScreen } from './features/login';
import { LoginMemberScreen } from './features/login/member';
// Home
import { HomeScreen } from './features/home';
import { HomeProjectScreen } from './features/home/member/project';
import { HomeSubprojectScreen } from './features/home/member/subproject';
// --------------------------------------------------------------------

import { TipeScreen } from './features/tipe';
import { DetailScreen } from './features/detail';
import { ClusterScreen } from './features/cluster';
import { SubclusterScreen } from './features/subcluster';

import { TableKPRScreen } from './features/table_kpr';
import { VerifyLinkScreen } from './features/verifyLink';
import { CheckMemberScreen } from './features/checkMember';

import { EventScreen } from './features/event';
import { EventDetailScreen } from './features/event/detail';


// External Import
import { ReactSession } from 'react-client-session';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { StatusMemberScreen } from './features/statusMember';
import { SharingScreen } from './features/sharing';
import { ChangeProfileScreen } from './features/changeProfile/member';
import { FwdScreen } from './features/fwd';


interface ProtectedProps { isPrivate: boolean, auth: string }
const ProtectedRoutes: React.FC<ProtectedProps> = ({ isPrivate, auth }) => {
  if (!isPrivate) {
    let dtAuth = ReactSession.get(auth) === undefined;
    if (!dtAuth) { return <Navigate to='/' replace />; }
    return <Outlet />;
  }
  let dtAuth = ReactSession.get(auth) === undefined;
  if (dtAuth) { return <Navigate to='/login' replace />; }
  return <Outlet />;
}

const ProtectedRoutesAdmin: React.FC<ProtectedProps> = ({ isPrivate, auth }) => {
  if (!isPrivate) {
    let dtAuth = ReactSession.get(auth) === undefined;
    if (!dtAuth) { return <Navigate to='/admin/content/dashboard' replace />; }
    return <Outlet />;
  }
  let dtAuth = ReactSession.get(auth) === undefined;
  if (dtAuth) { return <Navigate to='/admin/login' replace />; }
  return <Outlet />;
}


export interface RouterProps {}
export const RouterScreen: React.FC<RouterProps> = () => {
  ReactSession.setStoreType("sessionStorage");

  // const handelRouterLogin = () => {
  //   if (process.env.REACT_APP_PROJECT_CODE === 'ctrlnk') { return <Route path='/login' element={<LoginScreen />} />; }
  //   return <Route path='/login' element={<LoginMemberScreen />} />;
  // }

  // const handelRouterRegister = () => {
  //   if (process.env.REACT_APP_PROJECT_CODE === 'ctrlnk') { return <Route path='/register' element={<RegisterScreen />} />; }
  //   return <Route path='/register' element={<RegisterMemberScreen />} />;
  // }

  const handleRouterHome = () => {
    // if (process.env.REACT_APP_PROJECT_CODE==='ctrlnk') { return <Route path='/' element={<HomeScreen />} />; }
    if (process.env.REACT_APP_PROJECT_CODE==='cr' || process.env.REACT_APP_PROJECT_CODE==='cer') { return <Route path='/' element={<HomeSubprojectScreen />} />; }
    return <Route path='/' element={<HomeProjectScreen />} />;
  }

  return (
    <Routes>
      {/* Router USER Public */}
      <Route path='/fwd/:t' element={<FwdScreen />} />
      {/* Router USER without Login */}
      <Route element={<ProtectedRoutes isPrivate={false} auth={process.env.REACT_APP_AUTH!} />}>
        {/* {handelRouterLogin()} */}
        {/* {handelRouterRegister()} */}
        <Route path='/login' element={<LoginMemberScreen />} />;
        <Route path='/register' element={<RegisterMemberScreen />} />;
        <Route path='/check-member' element={<CheckMemberScreen />} />
        <Route path='/status-member/:id' element={<StatusMemberScreen />} />
        <Route path='/sharing/:p_id/:c_id/:sc_id/:m_id' element={<SharingScreen />} />
        {/* <Route path='/verify-link/:pid/:mid/:nu' element={<VerifyLinkScreen />} /> */}
      </Route>
      {/* Router USER with Login */}
      <Route element={<ProtectedRoutes isPrivate={true} auth={process.env.REACT_APP_AUTH!} />}>
        {handleRouterHome()}
        <Route path='/event' element={<EventScreen />} />
        <Route path='/table-kpr' element={<TableKPRScreen />} />
        <Route path='/tipe/:p_id/:sp_id' element={<TipeScreen />} />
        <Route path='/event/:e_id' element={<EventDetailScreen />} />
        <Route path='/change-profile' element={<ChangeProfileScreen />} />
        <Route path='/detail/:p_id/:c_id/:sc_id' element={<DetailScreen />} />
        <Route path='/cluster/:p_id/:sp_id/:t_id' element={<ClusterScreen />} />
        <Route path='/sub-cluster/:p_id/:t_id/:c_id' element={<SubclusterScreen />} />
      </Route>

      {/* Router ADMIN without Login */}
      {/* <Route element={<ProtectedRoutesAdmin isPrivate={false} auth={process.env.REACT_APP_AUTH_ADMIN!} />}>
        <Route path='/admin/login' />
      </Route> */}
      {/* Router ADMIN with Login */}
      {/* <Route element={<ProtectedRoutesAdmin isPrivate={true} auth={process.env.REACT_APP_AUTH_ADMIN!} />}>
        <Route path='/admin/content/:id' />
        <Route path='/admin/content/livestock/:id' />
      </Route> */}

    </Routes>
  );
}
